import React from "react"
import css from "@emotion/css"
export default function Form({ double }) {
  return (
    <form
      name="Application Form"
      className="flex flex-wrap  "
      netlify-honeypot="bot-field"
      data-netlify="true"
    >
      <input
        name="form-name"
        method="POST"
        value="Application Form"
        type="hidden"
      />
      <div
        className={
          double
            ? "w-full sm:w-1/2 flex flex-wrap   "
            : "w-full flex flex-wrap "
        }
      >
        <InputHalf placeholder="FIRST"></InputHalf>
        <InputHalf placeholder="LAST"></InputHalf>
        <InputFull placeholder="EMAIL"></InputFull>
        <InputFull placeholder="PHONE"></InputFull>
        <InputFull placeholder="BUSINESS NAME"></InputFull>
        <InputFull placeholder="STREET ADDRESS"></InputFull>
        <InputFull placeholder="STREET ADDRESS 2"></InputFull>
        <InputHalf placeholder="CITY"></InputHalf>
        <InputHalf placeholder="STATE/PROVINCE"></InputHalf>
        <InputHalf placeholder="POSTAL/ZIP CODE"></InputHalf>
        <InputHalf placeholder="SELECT COUNTRY"></InputHalf>
      </div>
      <div
        className={
          double ? "w-full sm:w-1/2   flex flex-col  " : "w-full flex flex-col "
        }
      >
        <InputFull placeholder="BUSINESS AGE"></InputFull>
        <InputFull placeholder="ANNUAL REVENUE"></InputFull>
        <InputFull placeholder="AMOUNT OF CAPITAL NEEDED?"></InputFull>
        <InputFull placeholder="DESIRED PRODUCT?"></InputFull>
        <InputFull placeholder="HOW FAST DO YOU NEED CAPITAL?"></InputFull>
        <div
          className="p-1  mt-auto  w-full "
          css={css`
            align-self: flex-end;
            justify-self: end;
          `}
        >
          {" "}
          <button
            type="submit"
            className="my-1 uppercase text-3xl bg-blue-700 w-full text-white py-4 md:py-6  lg:py-10 text-center font-bold"
          >
            Apply Now
          </button>
        </div>
      </div>
    </form>
  )
}

const InputFull = ({ placeholder = "", type = "text", value, onChange }) => {
  return (
    <div className="p-1  w-full  ">
      {" "}
      <input
        className="shadow-inner border border-gray-700  p-3 py-2 my-1 w-full text-sm lg:text-base"
        value={value}
        placeholder={placeholder}
        name={placeholder}
        type={type}
        onChange={onChange}
      ></input>
    </div>
  )
}
const InputHalf = ({ placeholder = "", type = "text", value, onChange }) => {
  return (
    <div className="p-1 w-full sm:w-1/2  ">
      <input
        className="shadow-inner border border-gray-700 p-3 py-2 my-1 w-full text-sm lg:text-base"
        value={value}
        placeholder={placeholder}
        name={placeholder}
        type={type}
        onChange={onChange}
      ></input>
    </div>
  )
}
